import React, { useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery/dist/jquery.min.js";
import "aos/dist/aos.css";
import "./App.css";
import Aos from "aos";
import Dashboard from "./app/generalComponent/Dashboard/Dashboard";
import { RTL } from "./Utility/RTLSupport";
import { ThemeProvider } from "@material-ui/core";
import { Customtheme } from "./Utility/theme";
import { getFromLocalStorage } from "./Utility/CacheStore";
import { Login } from "./app/Pages/Authentecation/AuthSlice";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setLoggedInUser = async () => {
    const token = await getFromLocalStorage("token");
    const user = await getFromLocalStorage("user");
    if (token) {
      await dispatch(Login({ token, user }));
      navigate("/");
    }
  };
  useEffect(() => {
    async function CheckUser() {
      await setLoggedInUser();
    }
    CheckUser();
  }, []);

  useEffect(() => {
    async function initAos() {
      Aos.init({ duration: 2000, delay: 0, offset: 100, once: false });
    }
    initAos();
  }, []);

  return (
    <RTL>
      <ThemeProvider theme={Customtheme}>
        <div className="App">
          <Dashboard />
        </div>
      </ThemeProvider>
    </RTL>
  );
}
export default App;
