import axios from "axios";
import { getFromLocalStorage } from "./CacheStore";

// export const baseURL = "http://127.0.0.1:8000/";
export const baseURL = "https://api.bareeqalittihad.com/";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const axiosWithAuthenticationInstance = axios.create({
  baseURL: baseURL,
  timeout: 300000,
  headers: {
    "Content-Type": "multipart/form-data",
    accept: "application/json",
  },
});

axiosWithAuthenticationInstance.interceptors.request.use(
  async function (config) {
    let token = await getFromLocalStorage("token");
    config.headers.Authorization = `token ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
