import { axiosWithAuthenticationInstance } from "../../../Utility/axiosInstance";

export const GetUserById = (id) =>
  axiosWithAuthenticationInstance.get(`/api/normal_user/${id}/`);

export const PostUser = async (e, postData) => {
  e.preventDefault();

  let groups = [];
  for (var item of postData.groups) {
    groups = groups.concat(item.id);
  }
  let permissions = [];
  for (var item of postData.user_permissions) {
    permissions = permissions.concat(item.id);
  }
  let formData = new FormData();

  formData.append("phone_number", postData.phone_number);
  formData.append("password", postData.password);
  formData.append("full_name", postData.full_name);
  formData.append("is_staff", postData.is_staff);
  formData.append("is_active", postData.is_active);
  formData.append("is_superuser", postData.is_superuser);
  return axiosWithAuthenticationInstance
    .post(`api/normal_user/`, formData)
    .then(function (response) {
      console.log(response.data);
      return { success: true, data: response.data };
    })
    .catch(function (error) {
      console.log(error.response.data);
      return { success: false, data: error.response.data };
    });
};
export const UpdateUser = async (e, postData) => {
  e.preventDefault();

  let groups = [];
  for (var item of postData.groups) {
    groups = groups.concat(item.id);
  }
  let permissions = [];
  for (var item of postData.user_permissions) {
    permissions = permissions.concat(item.id);
  }
  let formData = new FormData();

  formData.append("phone_number", postData.phone_number);
  formData.append("password", postData.password);
  formData.append("full_name", postData.full_name);
  formData.append("is_staff", postData.is_staff);
  formData.append("is_active", postData.is_active);
  formData.append("is_superuser", postData.is_superuser);

  return axiosWithAuthenticationInstance
    .put(`api/normal_user/${postData.id}/`, formData)
    .then(function (response) {
      console.log(response.data);
      return { success: true, data: response.data };
    })
    .catch(function (error) {
      console.log(error.response.data);
      return { success: false, data: error.response.data };
    });
};

export const DeleteUser = (id) =>
  axiosWithAuthenticationInstance.delete(`/api/normal_user/${id}/`);
