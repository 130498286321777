import { makeStyles } from "@material-ui/core";
export const DateInputProps = {
  disableUnderline: true,
  readOnly: true,
  style: {
    maxHeight: 50,
    borderRadius: 5,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
    marginInline: 5,
    marginBlock: 5,
  },
};
export const InputProps = {
  disableUnderline: true,
  style: {
    borderRadius: 5,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
    marginInline: 5,
    marginBlock: 5,
  },
};
export const ReadOnlyInputProps = {
  disableUnderline: true,
  readOnly: true,
  style: {
    borderRadius: 5,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
    marginInline: 5,
    marginBlock: 5,
  },
};
export const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  form: {
    padding: theme.spacing(3),
  },
  spaceAroundRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  withOutSpaceRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  withOutSpaceColumn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "",
  },
  input: {
    display: "none",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(5, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    // borderRadius:10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: "50px",
  },
  login: {
    display: "flex",
  },
}));
