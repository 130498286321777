import { makeStyles } from "@material-ui/core";
import shadows from "@material-ui/core/styles/shadows";
export const DateInputProps = {
  disableUnderline: true,
  readOnly: true,
  style: {
    maxHeight: 50,
    borderRadius: 5,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
    marginInline: 5,
    marginBlock: 5,
  },
};
export const InputProps = {
  disableUnderline: true,
  style: {
    borderRadius: 5,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
    marginInline: 5,
    marginBlock: 5,
  },
};
export const ReadOnlyInputProps = {
  disableUnderline: true,
  readOnly: true,
  style: {
    borderRadius: 5,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
    marginInline: 5,
    marginBlock: 5,
  },
};
export const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 1,
  },
  container: {
    width: "90%",
    height: "90%",
    overflowY: "auto",
    backgroundColor: "white",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100vh",
    },
    borderRadius: 5,
  },
  customButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  form: {
    padding: theme.spacing(3),
  },
  spaceAroundRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  withOutSpaceRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(3),
  },
  withOutSpaceColumn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "",
  },
  inputupload: {
    display: "none",
  },
  root: {
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(5, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    // borderRadius:10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: "50px",
  },
  liContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "50vh",
    maxHeight: "50vh",
    padding: 20,
    marginBottom: 10,
  },
  imgContainer: {
    // boxShadow:shadows[3]
  },
  img: {
    height: "40vh",
    objectFit: "cover",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: 20,
    maxHeight: "30vh",
    // flexFlow: "wrap-reverse",
    overflow: "scroll",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  p: {
    fontSize: "16px",
    wordBreak: "break-all",
    marginBlock: 5,
    marginInlineEnd: 10,
    fontWeight: "600",
  },
  pp: {
    fontSize: "15px",
    wordBreak: "break-all",
    marginBlock: 5,
    marginInlineEnd: 10,
  },
  searchRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "50%",
    marginBottom: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));
