import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertDialog({ open, handleClose, handleConfirm }) {
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color : "red"  }}>
          {/* {"Delete Alert"} */}
          {"تنبيه حذف"}
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Are you sure you want to delete the data? */}
            هل انت متاكد من حذف العنصر؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {/* cancel */}
            الغاء
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {/* confirm */}
            تاكيد
          </Button>
        </DialogActions>
      </Dialog>
  );
}
