import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseURL } from "../../../Utility/axiosInstance";
import { DeleteCars, GetCars, GetCarsById } from "./CarApi";
import Carousel from "react-bootstrap/Carousel";
import clsx from "clsx";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  Button,
  Fab,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Radio,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { EditModal } from "./Components/EditModal";
import { AddModal } from "./Components/AddModal";
import AlertDialog from "../../generalComponent/DeletingAlert";
import { useStyles } from "./Style/ModalStyle";
import { useSelector } from "react-redux";
import { selectUser } from "../Authentecation/AuthSlice";
import ImageViewer from "react-simple-image-viewer";

function AccountHistory() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [editeOpen, setEditeOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [apiResultValues, setApiResultValues] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedValue2, setSelectedValue2] = React.useState("true");
  const user = useSelector(selectUser);
  const [currentImage, setCurrentImage] = useState(0);
  const [currentImagesList, setCurrentImagesList] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [total, setTotal] = useState(0);

  const openImageViewer = useCallback((index, list) => {
    debugger;
    setCurrentImage(index);
    setCurrentImagesList(list);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChange2 = (event) => {
    setSelectedValue2(event.target.value);
  };

  // const [page, setPage] = useState(1);
  // const [pageCount, setPageCount] = useState(0);
  // const per_page = 20;

  // const LoadCars = async (_page = 1) => {
  //   await setPage(_page);
  //   const response = await GetCars(per_page, _page, searchInput);
  //   setCars(response.data.data);
  //   // setPageCount(Math.ceil(response.data.count / per_page));
  //   console.log(response);
  // };
  const LoadData = async () => {
    const response = await GetCars(
      searchInput,
      selectedValue,
      selectedValue2 === "true" ? true : false
    );
    let images = [];
    let total = 0;
    for (var item of response.data.data) {
      total += parseFloat(item.total) + parseFloat(item.other_cost);
      for (var arry of item.images) {
        images.push(baseURL + arry.image.slice(1));
      }
      item.images = images;
      images = [];
    }
    setData(response.data.data);
    setTotal(total);
    console.log(response.data.data);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = async () => {
    console.log("id " + rowId);
    await DeleteCars(rowId);
    await LoadData();
    setDeleteDialogOpen(false);
  };
  const LoadDetails = async (id) => {
    if (id !== null) {
      const response = await GetCarsById(id);
      let images = [];
      for (var item of response.data.images) {
        images.push({ file: null, image: baseURL + item.image.slice(1) });
      }
      const apiResultValues = {
        id: response.data.id,
        car_name: response.data.car_name,
        color: response.data.color,
        lot: response.data.lot,
        vin: response.data.vin,
        mazad_name: response.data.mazad_name,
        mazad_place: response.data.mazad_place,
        destination: response.data.destination,
        res_no: response.data.res_no,
        container: response.data.container,
        departure_port: response.data.departure_port,
        purchase_date: response.data.purchase_date,
        pay_date: response.data.pay_date,
        download_date: response.data.download_date,
        shipment_date: response.data.shipment_date,
        warehouse_expected_date: response.data.warehouse_expected_date,
        leave_expected_date: response.data.leave_expected_date,
        arrival_expected_date: response.data.arrival_expected_date,
        arrival_date: response.data.arrival_date,
        property: response.data.property,
        key: response.data.key,
        notes: response.data.notes,
        status: response.data.status,
        canceled: response.data.canceled,
        paied: response.data.paied,
        paied_by_customer: response.data.paied_by_customer,
        total: response.data.total,
        other_cost: response.data.other_cost,
        for_user: response.data.for_user ? response.data.for_user.id : "",
        for_user_textInput: response.data.for_user
          ? response.data.for_user.full_name
          : "",
        images: images,
      };
      // console.log(response);
      setApiResultValues(apiResultValues);
      console.log(apiResultValues);
    }
  };
  useEffect(() => {
    LoadData();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchInput);
      LoadData();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput, selectedValue, selectedValue2]);

  return (
    <div className="Car-Page" style={{ padding: 24 }}>
      {/* <Tooltip title="Add" aria-label="add" onClick={() => setAddOpen(true)}>
        <Fab color="primary" className={classes.fab}>
          <AddIcon />
        </Fab>
      </Tooltip> */}
      {/* {user.is_staff && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginBlockEnd: 20 }}
          onClick={() => setAddOpen(true)}
          startIcon={<AddIcon />}
        >
          جديد
        </Button>
      )}
      <EditModal
        data={apiResultValues}
        open={editeOpen}
        setOpen={setEditeOpen}
        refresh={LoadData}
      />
      <AddModal open={addOpen} setOpen={setAddOpen} refresh={LoadData} />
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handelConfirmDelete}
      />
      <Paper component="form" className={classes.searchRoot}>
        <InputBase
          className={classes.input}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="ابحث هنا..."
          inputProps={{ "aria-label": "ابحث هنا..." }}
        />
        <IconButton disabled className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper> */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          maxWidth: "100%",
          overflow: "scroll",
          marginBlock: 10,
          paddingInline: 10,
        }}
      >
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === ""}
              onChange={handleChange}
              value=""
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "" }}
            />
          }
          label="السيارات الغير واصلة"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === "e"}
              onChange={handleChange}
              value="e"
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "e" }}
            />
          }
          label="السيارات الواصلة"
        />
        {/* <FormControlLabel
          control={
            <Radio
              checked={selectedValue === ""}
              onChange={handleChange}
              value=""
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "" }}
            />
          }
          label="الكل"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === "a"}
              onChange={handleChange}
              value="a"
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "A" }}
            />
          }
          label="السيارات الجديدة"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === "b"}
              onChange={handleChange}
              value="b"
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "B" }}
            />
          }
          label="السحب"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === "c"}
              onChange={handleChange}
              value="c"
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "C" }}
            />
          }
          label="في المستودع"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === "d"}
              onChange={handleChange}
              value="d"
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "D" }}
            />
          }
          label="في الشحن"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === "e"}
              onChange={handleChange}
              value="e"
              color="primary"
              name="radio-button-demo"
              inputProps={{ "aria-label": "E" }}
            />
          }
          label="السيارات الواصلة"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === "f"}
              onChange={handleChange}
              value="f"
              color="primary"
              name="radio-button-demo"
              inputProps={{ "aria-label": "F" }}
            />
          }
          label="السيارات المستلمة"
        /> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          maxWidth: "100%",
          overflow: "scroll",
          marginBlock: 10,
          paddingInline: 10,
        }}
      >
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue2 === "true"}
              onChange={handleChange2}
              value={"true"}
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "" }}
            />
          }
          label="السيارات المدفوعة"
        />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue2 === "false"}
              onChange={handleChange2}
              value={"false"}
              name="radio-button-demo"
              color="primary"
              inputProps={{ "aria-label": "A" }}
            />
          }
          label="السيارات الغير مدفوع"
        />
      </div>
      <Row xs={1} md={1} className="g-4">
        {data?.map(function (item, i) {
          return (
            <>
              {isViewerOpen && (
                <ImageViewer
                  src={currentImagesList ? currentImagesList : []}
                  // src={item.images ? item.images : []}
                  currentIndex={currentImage}
                  disableScroll={true}
                  closeOnClickOutside={false}
                  onClose={closeImageViewer}
                  backgroundStyle={{
                    backgroundColor: "black",
                    opacity: 1,
                    zIndex: 999,
                  }}
                  closeComponent={
                    <CloseIcon fontSize="large" style={{ marginTop: 30 }} />
                  }
                  leftArrowComponent={<ArrowBackIos style={{ fontSize: 46 }} />}
                  rightArrowComponent={
                    <ArrowBackIos
                      style={{ fontSize: 46, transform: "scaleX(-1)" }}
                    />
                  }
                />
              )}
              <Card>
                {/* {user.is_staff && (
                  <div>
                    <IconButton
                      onClick={() => {
                        LoadDetails(item.id);
                        setEditeOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDeleteDialogOpen(true);
                        setRowId(item.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )} */}
                <Card.Header>
                  <Row xs={2} md={3}>
                    <Col>
                      <div className={classes.infoContainer}>
                        <p className={classes.p}>سعر السيارة</p>
                        <p
                          className={classes.pp}
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {parseFloat(item.total)}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      {" "}
                      <div className={classes.infoContainer}>
                        <p className={classes.p}>اجور اضافية</p>
                        <p
                          className={classes.pp}
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {parseFloat(item.other_cost)}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      {" "}
                      <div className={classes.infoContainer}>
                        <p className={classes.p}>المجموع</p>
                        <p
                          className={classes.pp}
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {parseFloat(item.total) + parseFloat(item.other_cost)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Header>
                <Row xs={1} md={2}>
                  <Col>
                    <Carousel interval={null} className={classes.imgContainer}>
                      {item.images?.map((image, ii) => (
                        <Carousel.Item key={"img" + ii} className={classes.img}>
                          <img
                            onClick={() => openImageViewer(ii, item.images)}
                            className={clsx("d-block w-100", classes.img)}
                            src={image}
                            alt="img"
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Col>
                  <Col>
                    <Card.Body>
                      <Card.Title>{item.car_name}</Card.Title>
                      <Card.Text>
                        <div className={classes.details}>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>لون السيارة</p>
                            <p className={classes.pp}>{item.color}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>لوت</p>
                            <p className={classes.pp}>{item.lot}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>الشاصي</p>
                            <p className={classes.pp}>{item.vin}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>اسم المزاد</p>
                            <p className={classes.pp}>{item.mazad_name}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>مكان المزاد</p>
                            <p className={classes.pp}>{item.mazad_place}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>الوجهة</p>
                            <p className={classes.pp}>{item.destination}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>رقم الحجز</p>
                            <p className={classes.pp}>{item.res_no}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>الحاوية</p>
                            <p className={classes.pp}>{item.container}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>ميناء المغادرة</p>
                            <p className={classes.pp}>{item.departure_port}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>تاريخ الشراء</p>
                            <p className={classes.pp}>{item.purchase_date}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>تاريخ الدفع</p>
                            <p className={classes.pp}>{item.pay_date}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>تاريخ التحميل</p>
                            <p className={classes.pp}>{item.download_date}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>تاريخ الشحن</p>
                            <p className={classes.pp}>{item.shipment_date}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>المتوقع للمستودع</p>
                            <p className={classes.pp}>
                              {item.warehouse_expected_date}
                            </p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>المتوقع للمغادرة</p>
                            <p className={classes.pp}>
                              {item.leave_expected_date}
                            </p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>المتوقع للوصول</p>
                            <p className={classes.pp}>
                              {item.arrival_expected_date}
                            </p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>تاريخ الوصول</p>
                            <p className={classes.pp}>{item.arrival_date}</p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>الملكية</p>
                            <p className={classes.pp}>
                              {item.property ? "نعم" : "لا"}
                            </p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>المفتاح</p>
                            <p className={classes.pp}>
                              {item.key ? "نعم" : "لا"}
                            </p>
                          </div>
                          <div className={classes.infoContainer}>
                            <p className={classes.p}>الملاحظات</p>
                            <p className={classes.pp}>{item.notes}</p>
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </>
          );
        })}
        <div className={classes.infoContainer} style={{ backgroundColor:"gainsboro", borderRadius:5 }}>
          <p className={classes.p}>المجموع</p>
          <p
            className={classes.pp}
            style={{ color: "red", fontWeight: "bold" }}
          >
            {total}
          </p>
        </div>
      </Row>
    </div>
  );
}

export default AccountHistory;
