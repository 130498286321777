import { axiosWithAuthenticationInstance } from "../../../Utility/axiosInstance";

export const GetNotifications = () =>
  axiosWithAuthenticationInstance.get(
    `/api/notify/`
  );

export const GetNotificationById = (id) =>
   axiosWithAuthenticationInstance.get(
    `/api/notify_detail/${id}/`
  );

  export const PostNotifications = (e, postData) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("title", postData.title);
    formData.append("descriptions", postData.descriptions);
    return axiosWithAuthenticationInstance.post(`api/notify/`, formData);
  };
  
  export const UpdateNotifications = (e, postData) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("title", postData.title);
    formData.append("descriptions", postData.descriptions);
    return axiosWithAuthenticationInstance.put(
      `api/notify_detail/${postData.id}/`,
      formData
    );
  };

  export const DeleteNotifications = (id) =>
  axiosWithAuthenticationInstance.delete(`/api/notify_detail/${id}/`);