import { axiosWithAuthenticationInstance } from "../../../Utility/axiosInstance";

export const GetUserAutoComplete = (search = "") =>
  axiosWithAuthenticationInstance.get(`/api/users/?search=${search}`);

export const GetCars = (search = "", selectedValue = "") =>
  axiosWithAuthenticationInstance
    .get(`/api/car/?search=${search}&status=${selectedValue}`)
    .then(function (response) {
      console.log(response);
      return { success: true, data: response };
    })
    .catch(function (error) {
      console.log(error.response);
      return { success: false, data: error.response };
    });

export const GetCarsById = (id) =>
  axiosWithAuthenticationInstance.get(`/api/car_detail/${id}/`);

export const PostCars = (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("car_name", postData.car_name);
  formData.append("color", postData.color);
  formData.append("lot", postData.lot);
  formData.append("vin", postData.vin);
  formData.append("mazad_name", postData.mazad_name);
  formData.append("mazad_place", postData.mazad_place);
  formData.append("destination", postData.destination);
  formData.append("res_no", postData.res_no);
  formData.append("container", postData.container);
  formData.append("departure_port", postData.departure_port);
  formData.append("purchase_date", postData.purchase_date);
  formData.append("pay_date", postData.pay_date);
  formData.append("download_date", postData.download_date);
  formData.append("shipment_date", postData.shipment_date);
  formData.append("warehouse_expected_date", postData.warehouse_expected_date);
  formData.append("leave_expected_date", postData.leave_expected_date);
  formData.append("arrival_expected_date", postData.arrival_expected_date);
  formData.append("arrival_date", postData.arrival_date);
  formData.append("property", postData.property);
  formData.append("key", postData.key);
  formData.append("notes", postData.notes);
  formData.append("status", postData.status);
  formData.append("canceled", postData.canceled);
  formData.append("paied", postData.paied);
  formData.append("paied_by_customer", postData.paied_by_customer);
  formData.append("total", postData.total);
  formData.append("other_cost", postData.other_cost);
  formData.append("for_user", postData.for_user);
  if (postData.images.length > 0) {
    for (let i = 0; i < postData.images.length; i++) {
      formData.append(`car_images[${i}]image`, postData.images[i].file);
    }
  }
  return axiosWithAuthenticationInstance
    .post(`api/car/`, formData)
    .then(function (response) {
      console.log(response);
      return { success: true, data: response };
    })
    .catch(function (error) {
      console.log(error.response);
      return { success: false, data: error.response };
    });
};

export const UpdateCars = (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("car_name", postData.car_name);
  formData.append("color", postData.color);
  formData.append("lot", postData.lot);
  formData.append("vin", postData.vin);
  formData.append("mazad_name", postData.mazad_name);
  formData.append("mazad_place", postData.mazad_place);
  formData.append("destination", postData.destination);
  formData.append("res_no", postData.res_no);
  formData.append("container", postData.container);
  formData.append("departure_port", postData.departure_port);
  formData.append("purchase_date", postData.purchase_date);
  formData.append("pay_date", postData.pay_date);
  formData.append("download_date", postData.download_date);
  formData.append("shipment_date", postData.shipment_date);
  formData.append("warehouse_expected_date", postData.warehouse_expected_date);
  formData.append("leave_expected_date", postData.leave_expected_date);
  formData.append("arrival_expected_date", postData.arrival_expected_date);
  formData.append("arrival_date", postData.arrival_date);
  formData.append("property", postData.property);
  formData.append("key", postData.key);
  formData.append("notes", postData.notes);
  formData.append("status", postData.status);
  formData.append("canceled", postData.canceled);
  formData.append("paied", postData.paied);
  formData.append("paied_by_customer", postData.paied_by_customer);
  formData.append("total", postData.total);
  formData.append("other_cost", postData.other_cost);
  formData.append("for_user", postData.for_user);
  if (postData.images.length > 0) {
    for (let i = 0; i < postData.images.length; i++) {
      if (postData.images[i].file !== null) {
        formData.append(`car_images[${i}]image`, postData.images[i].file);
      }
    }
  }
  return axiosWithAuthenticationInstance
    .put(`api/car_detail/${postData.id}/`, formData)
    .then(function (response) {
      console.log(response);
      return { success: true, data: response };
    })
    .catch(function (error) {
      console.log(error.response);
      return { success: false, data: error.response };
    });
};

export const DeleteCars = (id) =>
  axiosWithAuthenticationInstance.delete(`/api/car_detail/${id}/`);
