import {
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  ImageList,
  ImageListItem,
  MenuItem,
  Modal,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { InputProps, useStyles } from "../Style/ModalStyle";
import {
  Form,
  GeneralInputForm,
} from "../../../generalComponent/GeneralInputForm";
import { GetUserAutoComplete, PostCars } from "../CarApi";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { DateInputProps } from "../Style/ModalStyle";
import BaseAutoComplete from "../../../generalComponent/BaseAutoComplete";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialFValues = {
  car_name: "",
  color: "",
  lot: "",
  vin: "",
  mazad_name: "",
  mazad_place: "",
  destination: "",
  res_no: "",
  container: "",
  departure_port: "",
  purchase_date: moment(new Date()).format("DD-MM-YYYY"),
  pay_date: moment(new Date()).format("DD-MM-YYYY"),
  download_date: moment(new Date()).format("DD-MM-YYYY"),
  shipment_date: moment(new Date()).format("DD-MM-YYYY"),
  warehouse_expected_date: moment(new Date()).format("DD-MM-YYYY"),
  leave_expected_date: moment(new Date()).format("DD-MM-YYYY"),
  arrival_expected_date: moment(new Date()).format("DD-MM-YYYY"),
  arrival_date: moment(new Date()).format("DD-MM-YYYY"),
  property: false,
  key: false,
  notes: "",
  status: "a",
  canceled: false,
  paied: false,
  paied_by_customer: false,
  total: "",
  other_cost: "",
  for_user: "",
  for_user_textInput: "",
  images: [],
};

export const AddModal = ({ open, setOpen, refresh }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [userOptions, setUserOptions] = React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("total" in fieldValues)
      temp.total = fieldValues.total ? "" : "الحقل مطلوب";
    if ("other_cost" in fieldValues)
      temp.other_cost = fieldValues.other_cost ? "" : "الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    convertToDefEventPara,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  async function handleImageChange(e) {
    if (e.target.files) {
      const tempArr = [];

      [...e.target.files].forEach((file) => {
        console.log("file >>> ", file);

        tempArr.push({
          file: file,
          image: URL.createObjectURL(file),
        });
      });
      await setValues({
        ...values,
        images: tempArr,
      });
    }
  }

  const fetchUserAutoCompleteData = async (search = "") => {
    const response = await GetUserAutoComplete(search);
    const users = await response.data.data;
    console.log(users);
    setUserOptions(users);
  };
  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label="الحالة"
                name="status"
                value={values.status}
                onChange={handleInputChange}
                {...(errors.status && {
                  error: true,
                  helperText: errors.status,
                })}
                defaultValue={"a"}
              >
                <MenuItem value={"a"}>جديدة</MenuItem>
                <MenuItem value={"b"}>في السحب</MenuItem>
                <MenuItem value={"c"}>في المستودع</MenuItem>
                <MenuItem value={"d"}>في الشحن</MenuItem>
                <MenuItem value={"e"}>واصلة</MenuItem>
                <MenuItem value={"f"}>مستلمة</MenuItem>
              </TextField>
            </div>
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label="المستخدم"
                name="for_user"
                variant="filled"
                size="small"
                customStyle={{ width: "100%", marginInline: 5, marginBlock: 0 }}
                options={userOptions}
                optionLable={"full_name"}
                fetchData={fetchUserAutoCompleteData}
                inputValue={values.for_user_textInput}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara(
                      "for_user",
                      newValue ? newValue.id : null
                    )
                  );
                }}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="مجموع المبلغ"
                name="total"
                value={values.total}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.total && {
                  error: true,
                  helperText: errors.total,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="اجور اضافية"
                name="other_cost"
                value={values.other_cost}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.other_cost && {
                  error: true,
                  helperText: errors.other_cost,
                })}
              />
            </div>
            <div
              className={classes.withOutSpaceRow}
              style={{ marginInline: 20 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    name="canceled"
                    checked={values.canceled}
                    onChange={(e) =>
                      handleInputChange(
                        convertToDefEventPara("canceled", e.target.checked)
                      )
                    }
                    color="primary"
                  />
                }
                label="ملغاة"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="paied"
                    checked={values.paied}
                    onChange={(e) =>
                      handleInputChange(
                        convertToDefEventPara("paied", e.target.checked)
                      )
                    }
                    color="primary"
                  />
                }
                label="مدفوع"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="paied_by_customer"
                    checked={values.paied_by_customer}
                    onChange={(e) =>
                      handleInputChange(
                        convertToDefEventPara(
                          "paied_by_customer",
                          e.target.checked
                        )
                      )
                    }
                    color="primary"
                  />
                }
                label="مدفوع من قبل الزبون"
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="اسم السيارة"
                name="car_name"
                value={values.car_name}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.car_name && {
                  error: true,
                  helperText: errors.car_name,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="اللون"
                name="color"
                value={values.color}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.color && {
                  error: true,
                  helperText: errors.color,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="LOT"
                name="lot"
                value={values.lot}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.lot && {
                  error: true,
                  helperText: errors.lot,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="VIN"
                name="vin"
                value={values.vin}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.vin && {
                  error: true,
                  helperText: errors.vin,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="اسم المزاد"
                name="mazad_name"
                value={values.mazad_name}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.mazad_name && {
                  error: true,
                  helperText: errors.mazad_name,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="مكان المزاد"
                name="mazad_place"
                value={values.mazad_place}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.mazad_place && {
                  error: true,
                  helperText: errors.mazad_place,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="الوجهة"
                name="destination"
                value={values.destination}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.destination && {
                  error: true,
                  helperText: errors.destination,
                })}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  showTodayButton
                  label="تاريخ الشراء"
                  name="purchase_date"
                  value={
                    values.purchase_date
                      ? new Date(
                          moment(values.purchase_date, "DD-MM-YYYY").format(
                            "MM/DD/YYYY"
                          )
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "purchase_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />
                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  label="تاريخ الدفع"
                  showTodayButton
                  name="pay_date"
                  value={
                    values.pay_date
                      ? new Date(
                          moment(values.pay_date, "DD-MM-YYYY").format(
                            "MM/DD/YYYY"
                          )
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "pay_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />

                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  label="تاريخ السحب من المزاد"
                  showTodayButton
                  name="shipment_date"
                  value={
                    values.shipment_date
                      ? new Date(
                          moment(values.shipment_date, "DD-MM-YYYY").format(
                            "MM/DD/YYYY"
                          )
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "shipment_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />
                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  label="تاريخ الوصول للمستودع"
                  showTodayButton
                  name="warehouse_expected_date"
                  value={
                    values.warehouse_expected_date
                      ? new Date(
                          moment(
                            values.warehouse_expected_date,
                            "DD-MM-YYYY"
                          ).format("MM/DD/YYYY")
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "warehouse_expected_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="رقم الحجز"
                name="res_no"
                value={values.res_no}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.res_no && {
                  error: true,
                  helperText: errors.res_no,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="الحاوية"
                name="container"
                value={values.container}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.container && {
                  error: true,
                  helperText: errors.container,
                })}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  label="تاريخ التحميل"
                  showTodayButton
                  name="download_date"
                  value={
                    values.download_date
                      ? new Date(
                          moment(values.download_date, "DD-MM-YYYY").format(
                            "MM/DD/YYYY"
                          )
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "download_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />
                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  label="تاريخ للمغادرة"
                  showTodayButton
                  name="leave_expected_date"
                  value={
                    values.leave_expected_date
                      ? new Date(
                          moment(
                            values.leave_expected_date,
                            "DD-MM-YYYY"
                          ).format("MM/DD/YYYY")
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "leave_expected_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />
                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  label="المتوقع للوصول للميناء"
                  showTodayButton
                  name="arrival_expected_date"
                  value={
                    values.arrival_expected_date
                      ? new Date(
                          moment(
                            values.arrival_expected_date,
                            "DD-MM-YYYY"
                          ).format("MM/DD/YYYY")
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "arrival_expected_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />
                <KeyboardDatePicker
                  // disableToolbar
                  InputProps={DateInputProps}
                  format="DD-MM-yyyy"
                  inputVariant="filled"
                  // variant="inline"
                  label="تاريخ الوصول للميناء"
                  showTodayButton
                  name="arrival_date"
                  value={
                    values.arrival_date
                      ? new Date(
                          moment(values.arrival_date, "DD-MM-YYYY").format(
                            "MM/DD/YYYY"
                          )
                        )
                      : ""
                  }
                  onChange={(date) => {
                    console.log(date);
                    handleInputChange(
                      convertToDefEventPara(
                        "arrival_date",
                        date ? date.format("DD-MM-YYYY") : ""
                      )
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="ميناء المغادرة"
                name="departure_port"
                value={values.departure_port}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.departure_port && {
                  error: true,
                  helperText: errors.departure_port,
                })}
              />
            </div>

            <div
              className={classes.withOutSpaceRow}
              style={{ marginInline: 20 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    name="property"
                    checked={values.property}
                    onChange={(e) =>
                      handleInputChange(
                        convertToDefEventPara("property", e.target.checked)
                      )
                    }
                    color="primary"
                  />
                }
                label="ملكية"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="key"
                    checked={values.key}
                    onChange={(e) =>
                      handleInputChange(
                        convertToDefEventPara("key", e.target.checked)
                      )
                    }
                    color="primary"
                  />
                }
                label="مفتاح"
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label="notes"
                name="notes"
                value={values.notes}
                onChange={handleInputChange}
                {...(errors.notes && {
                  error: true,
                  helperText: errors.notes,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <input
                accept="image/*"
                className={classes.inputupload}
                id="contained-button-file"
                multiple
                onChange={handleImageChange}
                type="file"
              />
              <label
                style={{ marginInline: 10 }}
                htmlFor="contained-button-file"
              >
                <Button
                  fullWidth
                  variant="contained"
                  component="span"
                  color="default"
                  startIcon={<CloudUploadIcon />}
                >
                  صور
                </Button>
              </label>
              <ImageList
                sx={{ width: 500, height: 450 }}
                style={{ width: "100%", marginInline: 10 }}
                cols={3}
                // rowHeight={164}
              >
                {values.images?.map((item, index) => (
                  <ImageListItem key={index}>
                    <img
                      // src={`${item}?w=164&h=164&fit=crop&auto=format`}
                      src={item.image}
                      // srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={"Product Img"}
                      loading="lazy"
                      style={{
                        maxWidth: undefined,
                        maxHeight: undefined,
                        backgroundSize: "contain",
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
            <div className={classes.withOutSpaceRow}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    variant="contained"
                    style={{ marginInline: 20 }}
                    onClick={async (event) => {
                      setLoading(true);
                      console.log(values);
                      if (validate(values)) {
                        debugger;
                        let res = await PostCars(event, values);
                        if (res.success) {
                          setOpenSuccessAlert(true);
                          setLoading(false);
                        } else {
                          setOpenErrorAlert(true);
                          setLoading(false);
                        }
                      } else {
                        setOpenErrorAlert(true);
                        setLoading(false);
                      }
                    }}
                    startIcon={<SaveIcon />}
                  >
                    حفظ
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpen(false);
                      resetForm();
                    }}
                  >
                    الغاء
                  </Button>
                </>
              )}
            </div>
          </Form>
        </Container>
      </Modal>

      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
