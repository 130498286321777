import {
  Button,
  Container,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import { InputProps, useStyles } from "../Style/ModalStyle";
import {
  Form,
  GeneralInputForm,
} from "../../../generalComponent/GeneralInputForm";
import { UpdatePriceMenu } from "../PricingMenuApi";
import { baseURL } from "../../../../Utility/axiosInstance";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  title: "",
  file: "",
};
export const EditModal = ({ data, open, setOpen, refresh }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "الحقل مطلوب";
    if ("descriptions" in fieldValues)
      temp.descriptions = fieldValues.descriptions ? "" : "الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  async function handlePdfChange(e) {
    if (e.target.files) {
      console.log("file >>> ", e.target.files[0]);

      await setValues({
        ...values,
        file: e.target.files[0],
      });
      console.log(values);

    }
  }
  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label="العنوان"
                name="title"
                value={values.title}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.title && {
                  error: true,
                  helperText: errors.title,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <input
                accept=".pdf"
                className={classes.input}
                id="contained-button-file"
                // multiple
                onChange={handlePdfChange}
                type="file"
              />
              <label
                style={{ marginInline: 10 }}
                htmlFor="contained-button-file"
              >
                <Button
                  fullWidth
                  variant="contained"
                  component="span"
                  color="default"
                >
                  تحميل ملف
                </Button>
              </label>
              <Typography variant="subtitle1">
                {values.file && typeof values.file === "object"
                  ? values.file.name
                  : /[^/]*$/.exec(values.file.slice(1))}
              </Typography>
            </div>
            <div className={classes.withOutSpaceRow}>
              <Button
                variant="contained"
                style={{ marginInline: 20 }}
                onClick={ (event) => {
                  console.log(values);
                  if (validate(values)) {
                    setOpenSuccessAlert(true);
                    UpdatePriceMenu(event, values);
                  } else {
                    setOpenErrorAlert(true);
                  }
                }}
                startIcon={<SaveIcon />}
              >
                Create
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpen(false);
                  resetForm();
                }}
              >
                Close
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
