import { axiosWithAuthenticationInstance } from "../../../Utility/axiosInstance";

export const GetPriceMenu = () =>
  axiosWithAuthenticationInstance.get(`/api/price/`);

export const GetPriceMenuById = (id) =>
  axiosWithAuthenticationInstance.get(`/api/price_detail/${id}/`);

export const PostPriceMenu = (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("title", postData.title);
  formData.append("file", postData.file);
  return axiosWithAuthenticationInstance.post(`api/price/`, formData);
};

export const UpdatePriceMenu = (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("title", postData.title);
  if (typeof postData.file === "object") {
    formData.append("file", postData.file);
  }
  return axiosWithAuthenticationInstance.put(
    `api/price_detail/${postData.id}/`,
    formData
  );
};

export const DeletePriceMenu = (id) =>
  axiosWithAuthenticationInstance.delete(`/api/price_detail/${id}/`);
