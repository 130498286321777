import { Link, Typography } from "@material-ui/core";

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop:10 }}>
      {"Copyright © P. Saif Ali "}
      <Link color="inherit" href="mailto:saif.itbabylon@gmail.com">
        saif.itbabylon@gmail.com
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}
