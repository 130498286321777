import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faGauge,
  faHouse,
  faCar,
  faDollar,
  faBell
} from "@fortawesome/free-solid-svg-icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import { IconButton } from "@material-ui/core";
import { useStyles } from "./MenuListItemsStyle";
import { withStyles } from "@material-ui/core";
const routes = [
  // {
  //   path: "/",
  //   name: "Dashboard",
  //   icon: <FontAwesomeIcon size="xl" icon={faGauge} />,
  // },
  // {
  //   path: "/CarPurchaseSaleContract",
  //   name: "مكاتبة بيع و شراء سيارة",
  //   icon: <FontAwesomeIcon size="xl" icon={faCar} />,
  //   // subRoutes: [
  //   // ],
  // },
  {
    path: "/Cars",
    name: "السيارات",
    icon: <FontAwesomeIcon size="xl" icon={faCar} />,
    // subRoutes: [
    // ],
  },
  {
    path: "/Notifications",
    name: "التعميمات",
    icon: <FontAwesomeIcon size="xl" icon={faBell} />,
  },
  {
    path: "/Pricing",
    name: "قائمة الاسعار",
    icon: <FontAwesomeIcon size="xl" icon={faDollar} />,
  },
];

const CustomListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#FFFFFF",
      // color: "white",
      // borderStart: "solid",
      // borderStartColor: "#FF885E",
      // borderStartWidth: "5px",
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#FFFFFF",
      // color: "white",
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "#707070",
      },
    },
  },
  selected: {},
})(ListItem);

function SubMenuListItems({ mainRoute, routes, isMenuOpen }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleSubMenuToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="ListItemWithSubRouteContainer">
        <ListItem button onClick={handleSubMenuToggle}>
          <ListItemIcon>{mainRoute.icon}</ListItemIcon>
          <ListItemText primary={mainRoute.name} />
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </ListItem>
      </div>
      <List
        className={clsx(
          classes.subRouteListHidden,
          isMenuOpen && open && classes.subRouteList
        )}
      >
        {routes.map((subRoute, index) => {
          return (
            <ListItem
              key={"sublist" + index}
              button
              component={Link}
              to={subRoute.path}
            >
              <ListItemIcon>{subRoute.icon}</ListItemIcon>
              <ListItemText primary={subRoute.name} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

function MainListItems({ isMenuOpen }) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const classes = useStyles();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <List className={classes.menuList}>
      {routes.map((route, index) => {
        if (route.subRoutes) {
          return (
            <SubMenuListItems
              key={"mainList" + index}
              mainRoute={route}
              routes={route.subRoutes}
              isMenuOpen={isMenuOpen}
            />
          );
        }
        return (
          <CustomListItem
            onClick={(event) => handleListItemClick(event, "mainlist" + index)}
            key={"mainlist" + index}
            button
            selected={selectedIndex === "mainlist" + index}
            component={Link}
            to={route.path}
          >
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText style={{ textAlign: "right" }} primary={route.name} />
          </CustomListItem>
        );
      })}
    </List>
  );
}
export default MainListItems;
