import MaterialTable from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { forwardRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Typography} from "@material-ui/core";

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline style={{ color: "red" }} {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit style={{ color: "#607d8b" }} {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref) => (
    <CloudDownloadRoundedIcon {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear style={{ fontSize: "1rem" }} {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  View: forwardRef((props, ref) => (
    <VisibilityIcon style={{ color: "#607d8b" }} {...props} ref={ref} />
  )),
  Refresh: forwardRef((props, ref) => <RefreshIcon {...props} ref={ref} />),
};

function BaseTable({
  title,
  columns,
  data,
  options,
  components,
  actions,
  refrence,
  style,
}) {
  return (
    <MaterialTable
      tableRef={refrence}
      style={style}
      actions={actions}
      components={components}
      icons={tableIcons}
      title={title}
      columns={columns}
      data={data}
      options={options}
      localization={{
        header: {
          actions: "خيارات",
        },
        pagination: {
          firstTooltip: <Typography component="span">الصفحة الاولى</Typography>,
          firstAriaLabel: "الصفحة الاولى",
          previousTooltip: <Typography component="span">الصفحة السابقة</Typography>,
          previousAriaLabel: "الصفحة السابقة",
          nextTooltip: <Typography component="span">الصفحة التالية</Typography>,
          nextAriaLabel: "الصفحة التالية",
          labelDisplayedRows: (data) => (
            <Typography component="span">
              `${data.from}-${data.to} من ${data.count}`
            </Typography>
          ),
          labelRowsPerPage: <Typography component="span">عنصر لكل صفحة</Typography>,
          lastTooltip: <Typography component="span">الصفحة الاخيرة</Typography>,
          lastAriaLabel: "الصفحة الاخيرة",
          labelRows: "عنصر",
        },

      }}
    />
  );
}

export default BaseTable;
