import React from "react";
import AboutUs from "../about-us/AboutUs";
import "./index.css";
function Index(props) {
  return (
    <>
      <div
      className="index-image-container"
        style={{
          width: "100%",
          minHeight:"100vh",
          backgroundImage: `url(${require("../../../Images/usedcars-hero.png")})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container text-center">
          <div className="intro-text">
            <div className="intro-lead-in">
              <span>
                نعمل على تسهيل نقل سياراتك بدءا من توفير حسابات المشتري و السحب
                و الشحن و التخليص بالاضافة الى ميزات التتبع الالكتروني في جميع
                الاوقات
              </span>
            </div>
            <div className="intro-heading text-uppercase">
              <span>نشحن سياراتك من جميع المزادات الامريكية</span>
            </div>
          </div>
        </div>
      </div>
      <AboutUs />
    </>
  );
}

export default Index;
