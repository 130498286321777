import axiosInstance from "../../../Utility/axiosInstance";

export const LoginUser = (username, password) => {
  let formData = {
    username: username,
    password: password,
  };
  return axiosInstance
    .post(`/api/login/`, JSON.stringify(formData))
    .then(function (response) {
      return response.data;
    });
};
