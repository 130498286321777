import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storeToLocalStorage } from "../../../Utility/CacheStore";
import { LoginUser } from "./AuthAPI";

const initialState = {
  loading: false,
  token: null,
  user: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const loginAndThenGetClientInfo = (user) => async (dispatch) => {
  return await dispatch(LoginAsync(user));
};

export const LoginAsync = createAsyncThunk("Auth/LoginAsync", async (user) => {
  const response = await LoginUser(user.username, user.password);
  // The value we return becomes the `fulfilled` action payload
  if (response.token && user.remember_me) {
    storeToLocalStorage("token", response.token);
    storeToLocalStorage("user", response.user);
  }
  return response;
});


export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    Login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    Logout: (state) => {
      state.token = null;
      state.user = null;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //------------------------Login--------------------------//
      .addCase(LoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginAsync.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(LoginAsync.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const { Login, Logout } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state) => state.auth.user;
export const selectLoading = (state) => state.auth.loading;
export const selectToken = (state) => state.auth.token;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default authSlice.reducer;
