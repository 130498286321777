import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  DeletePriceMenu,
  GetPriceMenu,
  GetPriceMenuById,
} from "./PricingMenuApi";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { baseURL } from "../../../Utility/axiosInstance";
import { useStyles } from "./Style/ModalStyle";
import { Button, Fab, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditModal } from "./Components/EditModal";
import { AddModal } from "./Components/AddModal";
import AlertDialog from "../../generalComponent/DeletingAlert";
import { useSelector } from "react-redux";
import { selectUser } from "../Authentecation/AuthSlice";

function PricingMenuPage() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [editeOpen, setEditeOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [apiResultValues, setApiResultValues] = useState(null);
  const [rowId, setRowId] = useState(null);
  const user = useSelector(selectUser);

  const LoadData = async () => {
    const response = await GetPriceMenu();
    setData(response.data.data);
    console.log(response);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = async () => {
    console.log("id " + rowId);
    await DeletePriceMenu(rowId);
    await LoadData();
    setDeleteDialogOpen(false);
  };
  const LoadDetails = async (id) => {
    if (id !== null) {
      const response = await GetPriceMenuById(id);
      const apiResultValues = {
        id: response.data.id,
        title: response.data.title,
        file: response.data.file,
      };
      // console.log(response);
      setApiResultValues(apiResultValues);
      console.log(apiResultValues);
    }
  };
  useEffect(() => {
    LoadData();
  }, []);

  return (
    <div className="Car-Page" style={{ padding: 24 }}>
      {/* <Tooltip title="Add" aria-label="add" onClick={() => setAddOpen(true)}>
        <Fab color="primary" className={classes.fab}>
          <AddIcon />
        </Fab>
      </Tooltip> */}
      {user.is_staff && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginBlockEnd: 20 }}
          onClick={() => setAddOpen(true)}
          startIcon={<AddIcon />}
        >
          جديد
        </Button>
      )}
      <EditModal
        data={apiResultValues}
        open={editeOpen}
        setOpen={setEditeOpen}
        refresh={LoadData}
      />
      <AddModal open={addOpen} setOpen={setAddOpen} refresh={LoadData} />
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handelConfirmDelete}
      />

      <Row xs={1} md={4} className="g-4">
        {data?.map(function (item, i) {
          return (
            <Col>
              <Card>
                {user.is_staff && (
                  <div>
                    <IconButton
                      onClick={() => {
                        LoadDetails(item.id);
                        setEditeOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDeleteDialogOpen(true);
                        setRowId(item.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
                <Card.Body>
                  <Card.Text style={{ textAlign: "center" }}>
                    <a
                      href={baseURL + item.file.slice(1)}
                      type="application/pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require("../../../Images/pdf.png")}
                        alt="pdf-img"
                        width={"50%"}
                      ></img>
                    </a>
                  </Card.Text>
                  <Card.Title style={{ textAlign: "center", marginBlock: 5 }}>
                    {item.title}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default PricingMenuPage;
