import { createTheme } from "@material-ui/core";

export const Customtheme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#F68E1A",
    },
    secondary: {
      main: "#707070",
    },
  },
});
