import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  subRouteListHidden:{
    display:"none",
  },
  subRouteList:{
    backgroundColor:theme.palette.grey[100],
    display:"block",
  },
  menuList:{

  }
}));