/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FaceBook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import { ChevronRight } from "@material-ui/icons";
import { useStyles } from "./DashboardStyle";
import MainListItems from "../MenuList/MenuListItems";
import { Button, CssBaseline, useScrollTrigger } from "@material-ui/core";
import Index from "../../Pages/index";
import LoginPage from "../../Pages/Authentecation/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import {
  Logout,
  selectToken,
  selectUser,
} from "../../Pages/Authentecation/AuthSlice";
import { removeFromLocalStorage } from "../../../Utility/CacheStore";
import CarPage from "../../Pages/Cars/CarPage";
import NotifyPage from "../../Pages/Notifications/NotifyPage";
import PricingMenuPage from "../../Pages/PricingMenu/PricingMenuPage";
import { Copyright } from "../Copyright";
import AboutUs from "../../Pages/about-us/AboutUs";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import UsersPage from "../../Pages/users/UsersPage";
import ContactUs from "../../Pages/contact-us/contact-us";
import AccountHistory from "../../Pages/AccountHistory/AccountHistoryPage";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Dashboard(props) {
  document.documentElement.dir = "rtl";
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  const handelLogout = async () => {
    await removeFromLocalStorage("token");
    await dispatch(Logout());
    navigate("/");
  };

  return (
    <>
      {/* <ElevationScroll {...props}> */}
      <Navbar style={{ backgroundColor: "#F68E1A" }} expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand
            href="/index"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "black",
              cursor: "pointer",
              marginBlockStart: 5,
              marginInlineEnd: 5,
            }}
          >
            <img
              className={classes.logoHeaderImg}
              src={require("../../../Images/logo.png")}
              alt="logo"
            />
            <Typography variant="h6" className={classes.title}>
              بريق الاتحاد
            </Typography>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 w-100 "
              style={{ maxHeight: "200px" }}
              navbarScroll
            >
              <Nav.Link
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                حول الشركة
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                تواصل معنا
              </Nav.Link>
              {token && (
                <>
                  <Nav.Link
                    onClick={() => {
                      navigate("/Cars");
                    }}
                  >
                    السيارات
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/Notifications");
                    }}
                  >
                    التعميمات
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/Pricing");
                    }}
                  >
                    قائمة الاسعار
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/AccountHistory");
                    }}
                  >
                    سجل الحسابات
                  </Nav.Link>
                  {user.is_staff && (
                    <Nav.Link
                      onClick={() => {
                        navigate("/Users");
                      }}
                    >
                      المستخدمين
                    </Nav.Link>
                  )}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            {token ? (
              <Navbar.Text onClick={handelLogout}>
                <Button>تسجيل الخروج</Button>
              </Navbar.Text>
            ) : (
              <Navbar.Text
                onClick={() => {
                  navigate("/Login");
                }}
              >
                <Button>تسجيل الدخول</Button>
              </Navbar.Text>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<Navigate to="/index" />} />
        <Route path="/index" element={<Index />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/Login" element={<LoginPage />} />
        {token && (
          <>
            <Route path="/Cars" element={<CarPage />} />
            <Route path="/Notifications" element={<NotifyPage />} />
            <Route path="/Pricing" element={<PricingMenuPage />} />
            <Route path="/Users" element={<UsersPage />} />
            <Route path="/AccountHistory" element={<AccountHistory />} />
          </>
        )}
      </Routes>

      <footer className={classes.footerStyles}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <img
                src={require("../../../Images/logo.png")}
                alt="logo"
                width={100}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <h5>الفروع الرئيسية</h5>
              <h6>العراق / بابل / شارع اربعين</h6>
              <h6>الامارات / الشارقة/ منطقة الصناعية الرابعة</h6>
            </div>
            <div className="col-sm-12 col-md-4">
              <div style={{ display: "flex" }}>
                <h5 style={{ marginInline: 5 }}>تابعنا</h5>
                <a href="https://www.facebook.com/kararalnaji2020?mibextid=LQQJ4d" style={{ marginInline: 5 }}>
                  <FaceBook />
                </a>

                <a href="https://instagram.com/bareeqalittihad_iq?igshid=Mzc1MmZhNjY=" style={{ marginInline: 5 }}>
                  <Instagram />
                </a>
              </div>
              <h6>Bareeqalittihad2023@gmail.com</h6>
              <h6>يوجد لدينا فروع في كافة المحافظات</h6>
            </div>
          </div>
        </div>
      </footer>
      <p className="copyright">
        <Copyright />
      </p>
    </>
  );
}
