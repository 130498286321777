import {
  Button,
  Container,
  FormControlLabel,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles, InputProps } from "../Style/ModalStyle";
import { UpdateUser } from "../UsersAPI";
import { Form, GeneralInputForm } from "../../../generalComponent/GeneralInputForm";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: 0,
  password: "",
  full_name: "",
  phone_number: "",
  is_staff: false,
  is_active: true,
  is_superuser: false,
  groups: [],
  user_permissions: [],
};;

export const EditModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("phone_number" in fieldValues)
      temp.phone_number = fieldValues.phone_number ? "" : "This field is required.";
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";
    if ("full_name" in fieldValues)
      temp.full_name = fieldValues.full_name ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    convertToDefEventPara,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  const onCreate = async (event) => {
    console.log(values);
    console.log(errors);
    if (validate(values)) {
      let result = await UpdateUser(event, values);
      if (result.success) {
        setOpenSuccessAlert(true);
      } else {
        console.log(result);
        let temp = { ...errors };
        let data = result.data;
        for (var item in data) {
          for (let i = 0; i < data[`${item}`].length; i++) {
            temp[`${item}`] = data[`${item}`][i];
            console.log(data[`${item}`][i]);
          }
        }
        setErrors({
          ...temp,
        });
        setOpenErrorAlert(true);
      }
    } else {
      setOpenErrorAlert(true);
    }
  };
  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
             <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label="الاسم الكامل"
                name="full_name"
                value={values.full_name}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.full_name && {
                  error: true,
                  helperText: errors.full_name,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
             <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label="رقم الهاتف"
                name="phone_number"
                value={values.phone_number}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.phone_number && {
                  error: true,
                  helperText: errors.phone_number,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
             
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label="الرقم السري"
                name="password"
                value={values.password}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.password && {
                  error: true,
                  helperText: errors.password,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_active"
                      checked={values.is_active}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara("is_active", e.target.checked)
                        )
                      }
                      color="primary"
                    />
                  }
                  label="نشط"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_staff"
                      checked={values.is_staff}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara("is_staff", e.target.checked)
                        )
                      }
                      color="primary"
                    />
                  }
                  label="موظف"
                />
              </div>
              {/* <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_superuser"
                      checked={values.is_superuser}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara(
                            "is_superuser",
                            e.target.checked
                          )
                        )
                      }
                      color="primary"
                    />
                  }
                  label="مدير"
                />
              </div> */}
            </div>
            {/* <h6>permissions_groups</h6>
            <div className={classes.withOutSpaceRow}>
              <Groups
                right={values.groups}
                setRight={(value) =>
                  handleInputChange(convertToDefEventPara("groups", value))
                }
              />
            </div> */}
            {/* <h6>Permissions</h6>
            <div className={classes.withOutSpaceRow}>
              <Permissions
                right={values.user_permissions}
                setRight={(value) =>
                  handleInputChange(
                    convertToDefEventPara("user_permissions", value)
                  )
                }
              />
            </div> */}
            <div className={classes.withOutSpaceRow}>
              <Button
                variant="contained"
                style={{ marginInline: 20 }}
                onClick={onCreate}
                startIcon={<SaveIcon />}
              >
                حفظ
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpen(false);
                  resetForm();
                }}
              >
                الغاء
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>

      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
