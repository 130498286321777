import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  DeleteNotifications,
  GetNotificationById,
  GetNotifications,
} from "./NotifyApi";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button, Fab, IconButton, Tooltip } from "@material-ui/core";
import { useStyles } from "./Style/ModalStyle";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditModal } from "./Components/EditModal";
import { AddModal } from "./Components/AddModal";
import AlertDialog from "../../generalComponent/DeletingAlert";
import { useSelector } from "react-redux";
import { selectUser } from "../Authentecation/AuthSlice";

function NotifyPage() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [editeOpen, setEditeOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [apiResultValues, setApiResultValues] = useState(null);
  const [rowId, setRowId] = useState(null);
  const user = useSelector(selectUser);

  const LoadData = async () => {
    const response = await GetNotifications();
    setData(response.data.data);
    console.log(response);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = async () => {
    console.log("id " + rowId);
    await DeleteNotifications(rowId);
    await LoadData();
    setDeleteDialogOpen(false);
  };
  const LoadDetails = async (id) => {
    if (id !== null) {
      const response = await GetNotificationById(id);
      const apiResultValues = {
        id: response.data.id,
        title: response.data.title,
        descriptions: response.data.descriptions,
      };
      // console.log(response);
      setApiResultValues(apiResultValues);
      console.log(apiResultValues);
    }
  };
  useEffect(() => {
    LoadData();
  }, []);

  return (
    <div className="Car-Page" style={{ padding: 24 }}>
      {/* <Tooltip title="Add" aria-label="add" onClick={() => setAddOpen(true)}>
        <Fab color="primary" className={classes.fab}>
          <AddIcon />
        </Fab>
      </Tooltip> */}
      {user.is_staff && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginBlockEnd: 20 }}
          onClick={() => setAddOpen(true)}
          startIcon={<AddIcon />}
        >
          جديد
        </Button>
      )}
      <EditModal
        data={apiResultValues}
        open={editeOpen}
        setOpen={setEditeOpen}
        refresh={LoadData}
      />
      <AddModal open={addOpen} setOpen={setAddOpen} refresh={LoadData} />
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handelConfirmDelete}
      />

      <Row xs={1} md={1} className="g-4">
        {data?.map(function (item, i) {
          return (
            <Col>
              <Card>
                {user.is_staff && (
                  <div>
                    <IconButton
                      onClick={() => {
                        LoadDetails(item.id);
                        setEditeOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDeleteDialogOpen(true);
                        setRowId(item.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
                <Card.Header style={{ textAlign: "center" }}>
                  {item.title}
                </Card.Header>
                <Card.Body>
                  <Card.Text style={{ textAlign: "center" }}>
                    {item.descriptions}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default NotifyPage;
