import { Link } from "@material-ui/core";
import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import "./contact-us.css";
function ContactUs(props) {
  return (
    <>
      <div className="index-image-container container">
        <Row className="d-flex justify-content-center align-items-center mt-3">
          <h1 className="text-center">قم بزيارتنا </h1>
          <Col className=" mt-3">
            <h5 className="text-center">
              شركة بريق الاتحاد
              <br />
              للتجارة العامة
              <br />
              بابل - الحله
            </h5>
          </Col>
          <Col className=" mt-3">
            <div className="card" style={{ width: "18rem" }}>
              <div class="card-body">
                <img src={require("../../../Images/location.png")} alt="img" style={{ width:"100%" }} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className=" mt-3">
          <h1 className="text-center">تواصل معنا </h1>
          <h6 className="text-center">
            مرحباً بك للاتصال بنا او إرسال رسالة نصية او مراسلتنا عبر البريد
            الالكتروني على التفاصيل التالية{" "}
          </h6>
        </Row>
        <Row className=" mt-3">
          <Col>
            <h1 className="text-center mt-3">الهاتف</h1>
            <h5 className="text-center mt-3 text-info">07810907077</h5>
            <h5 className="text-center mt-3 text-info">07700822022</h5>
            <h5 className="text-center mt-3 text-info">07812200740</h5>
            <h5 className="text-center mt-3 text-info">07822220464</h5>
          </Col>
          <Col>
            <h1 className="text-center mt-3">البريد الالكتروني</h1>
            <h5 className="text-center mt-3 text-info">
              Bareeqalittihad2023@gmail.com
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <div
            className="bg-light justify-content-center"
            style={{
              width: "75%",
              padding: 0,
              borderTopLeftRadius: "1.5rem",
              borderTopRightRadius: "1.5rem",
              overflow: "hidden",
            }}
          >
            <img
              src={require("../../../Images/offshore-call-center.jpg")}
              alt="img"
              style={{ width: "100%" }}
            ></img>
            <h5 className="text-center mt-3">
              WhatsApp يمكنك دائما إرسال رسالة نصية إلينا
            </h5>
            <h5 className="text-center">
              <Link className="mt-3 mb-3" style={{ textDecoration:"none" }} href="https://wa.me/9647812200740">تواصل معنا</Link>
            </h5>
          </div>
        </Row>
        <Row className="justify-content-center mt-3">
          <h1 className="text-center mt-3">اوقات العمل</h1>
          <div className="card mt-3 mb-3" style={{ width: "75%" }}>
            <Row className="card-header">
              <Col className="text-center h5">ايام الاسبوع</Col>
              <Col className="text-center h5">الوقت</Col>
            </Row>
            <ul className="list-group list-group-flush">
              <Row>
                <Col className="text-center">السبت - الخميس</Col>
                <Col className="text-center">٩:٠٠ صباحاً - ٢:٠٠ ظهراً</Col>
              </Row>
              <Row>
                <Col className="text-center">الجمعة</Col>
                <Col className="text-center">عطلة</Col>
              </Row>
            </ul>
          </div>
        </Row>
      </div>
    </>
  );
}

export default ContactUs;
