import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core";
import shadows from "@material-ui/core/styles/shadows";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.5em",
      // display: "none",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 5px rgba(0,0,0,0.0)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      borderRadius: 5,
    },
  },
  logoHeaderImg: {
    width: "2.5rem",
    marginInlineEnd: "5px",
  },
  footerStyles: {
    color: "white",
    background: "#343a40 ",
    padding: "1%",
  },

}));
