import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
// import { Copyright } from "../../app/generalComponent/Copyright";
import { InputProps, useStyles } from "./styles/LoginPageStyle";
import { useDispatch, useSelector } from "react-redux";
import { Login, loginAndThenGetClientInfo, selectToken } from "./AuthSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Switch } from "@material-ui/core";
import { GeneralInputForm } from "../../generalComponent/GeneralInputForm";
import { getFromLocalStorage } from "../../../Utility/CacheStore";

// import { useContext } from "react";

const initialFValues = {
  username: "",
  password: "",
  remember_me: true,
};
export default function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector(selectToken);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("username" in fieldValues)
      temp.username = fieldValues.username ? "" : "This field is required.";
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const setLoggedInUser = async () => {
    const token = await getFromLocalStorage("token");
    const user = await getFromLocalStorage("user");
    const clientinfo = await getFromLocalStorage("clientinfo");
    if (token) {
      await dispatch(Login({ token, user, clientinfo }));
      navigate("/");
    }
  };
  useEffect(() => {
    async function CheckUser() {
      await setLoggedInUser();
    }
    CheckUser();
  }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        conta
        square
        elevation={6}
        component={Paper}
        className={classes.login}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              id="email"
              variant="filled"
              InputProps={InputProps}
              fullWidth
              margin="normal"
              size="small"
              label="رقم الهاتف"
              name="username"
              autoComplete="email"
              required
              value={values.username}
              onChange={handleInputChange}
              {...(errors.username && {
                error: true,
                helperText: errors.username,
              })}
            />
            <TextField
              id="password"
              variant="filled"
              InputProps={InputProps}
              fullWidth
              margin="normal"
              size="small"
              label="الرمز السري"
              type="password"
              name="password"
              autoComplete="current-password"
              required
              value={values.password}
              onChange={handleInputChange}
              {...(errors.password && {
                error: true,
                helperText: errors.password,
              })}
            />
            {/* <FormControlLabel
              style={{ width: "100%", marginInline: 5 }}
              control={
                <Switch
                  name="remember_me"
                  checked={values.remember_me}
                  onChange={(e) =>
                    handleInputChange(
                      convertToDefEventPara("remember_me", e.target.checked)
                    )
                  }
                  color="primary"
                />
              }
              label="تذكرني"
            /> */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                console.log(values);
                dispatch(
                  loginAndThenGetClientInfo({
                    username: values.username,
                    password: values.password,
                    remember_me: values.remember_me,
                  })
                ).then(() => {
                  if (location.state?.form) {
                    navigate(location.state.form);
                  } else {
                    navigate("/");
                  }
                });
              }}
            >
              تسجيل دخول
            </Button>

            {/* <Link href="#" variant="body2">
              Forgot password?
            </Link> */}
            {/* 
            <Box mt={5}>
              <Copyright />
            </Box> */}
          </form>
        </div>
      </Grid>
    </div>
  );
}
