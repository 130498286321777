import { makeStyles } from "@material-ui/core";
export const InputProps={
  disableUnderline: true,
  style: {
    borderRadius: 5,
    // backgroundColor: "white",
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cccccc",
  },
}
export const ReadOnlyInputProps={
  disableUnderline: true,
  readOnly: true,
  style: {
    borderRadius: 5,
    // backgroundColor: "white",
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cccccc",
  },
}
export const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 1,
  },
  container: {
    width: "50%",
    height: "50%",
    overflowY: "auto",
    backgroundColor: "white",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100vh",
    },
    borderRadius:5,
  },
  form: {
    padding: theme.spacing(3),
  },
  spaceAroundRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: theme.spacing(3),
  },
  withOutSpaceRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(3),
  },
}));
