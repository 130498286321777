import React from "react";
import "./aboutUs.css";
function AboutUs() {
  return (
    <div className="about-us-page">
      <div className="container" style={{ marginTop: 10 }}>
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="text-uppercase">نبذة عن تاريخنا</h2>
            <h3 className="text-muted section-subheading">
              بدأ مشوارنا بالعمل التجاري
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ul className="list-group timeline">
              <li className="list-group-item">
                <div className="timeline-image">
                  <img
                    className="rounded-circle img-fluid"
                    src={require("../../../Images/1.jpeg")}
                    alt="img"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>2006-2007</h4>
                    <h4 className="subheading">بدايتنا</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      في بداية 2006 حيث تمحور نشاطنا التجاري في بيع وشراء
                      السيارات فضلا عن الخدمات اللوجستية داخل وخارج العراق
                    </p>
                  </div>
                </div>
              </li>
              <li className="list-group-item timeline-inverted">
                <div className="timeline-image">
                  <img
                    className="rounded-circle img-fluid"
                    src={require("../../../Images/2.jpeg")}
                    alt="img"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>في 2010</h4>
                    <h4 className="subheading">الافتتاح</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      في عام 2010 افتتحنا رسمياً معرض الاتحاد لتجارة السيارات في
                      محافظة بابل
                    </p>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="timeline-image">
                  <img
                    className="rounded-circle img-fluid"
                    src={require("../../../Images/3.jpeg")}
                    alt="img"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>في 2011</h4>
                    <h4 className="subheading">توسع نطاق العمل</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      في عام 2011 بدأنا بأستيراد السيارات من الاردن والامارات
                      العربية والكويت
                    </p>
                  </div>
                </div>
              </li>
              <li className="list-group-item timeline-inverted">
                <div className="timeline-image">
                  <img
                    className="rounded-circle img-fluid"
                    src={require("../../../Images/4.jpeg")}
                    alt="img"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>في 2015</h4>
                    <h4 className="subheading">فتح خط تجاري</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      في عام 2015 تم فتح خط تجاري بأستيراد السيارات من المزادات
                      الامريكية (Copart - IAAI - Manheim) وشحنها الى العراق
                      والاردن والامارات
                    </p>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="timeline-image">
                  <img
                    className="rounded-circle img-fluid"
                    src={require("../../../Images/5.jpeg")}
                    alt="img"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>في 2022</h4>
                    <h4 className="subheading">التاسيس</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      في عام 2022 تم تأسيس شركة بريق الاتحاد للتجارة العامة
                      المجازة من قبل وزارة التجارة ودائرة مسجل الشركات المرقمة (
                      م.ش.أ._ ٢_ ١٧٦٢٦ ) والمؤرخة في تاريخ 2022/8/15
                    </p>
                  </div>
                </div>
              </li>
              <li className="list-group-item timeline-inverted">
                <div className="timeline-image">
                  <img
                    className="rounded-circle img-fluid"
                    src={require("../../../Images/6.jpeg")}
                    alt="img"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>في 2023</h4>
                    <h4 className="subheading">التوسع محليا</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      في عام 2023 تم تطوير عملنا وفتح فروع في انحاء العراق كافة
                      وفرعنا الثاني في محافظة بغداد
                    </p>
                  </div>
                </div>
              </li>
              <li className="list-group-item timeline-inverted">
                <div className="timeline-image">
                  <h4>
                    انضم
                    <br />
                    الينا
                    <br />
                    الان
                  </h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
